import { connect } from "react-redux";
import { selectClient, getClients } from "../../../../models/actions";
import "./clientsSelectionTab.scss";
import PropTypes from "prop-types";

const ClientsSelectionTab = ({ clients, selectClient, getClients }) => {
    return (
        <select
            name='clients'
            id='clients'
            className='clientsSelectionTab__select'
            onFocus={getClients}
            onChange={(e) => selectClient(e.target.value)}>
            <option value=''>Select Calendar</option>
            {clients &&
                clients.map((client, index) => (
                    <option
                        key={index}
                        value={client}>
                        {client.toUpperCase()}
                    </option>
                ))}
        </select>
    );
};

const mapStateToProps = (state) => ({
    clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
    selectClient: (payload) => dispatch(selectClient(payload)),
    getClients: (payload) => dispatch(getClients(payload)),
});

ClientsSelectionTab.propTypes = {
    clients: PropTypes.array,
    selectClient: PropTypes.func,
    getClients: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientsSelectionTab);
