import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const PasswordDisplay = ({
    clientId,
    password,
    isSelected,
    togglePasswordVisibility,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const displayPassword =
        isSelected && isVisible
            ? password
            : password === null
              ? "*".repeat("15")
              : "*".repeat(password.length + 15);

    return (
        <div className='password_cell'>
            {displayPassword}
            <div className='show_hide_icon'>
                {isVisible ? (
                    <VisibilityOffIcon onClick={() => setIsVisible(false)} />
                ) : (
                    <VisibilityIcon
                        onClick={() => {
                            setIsVisible(true);
                            togglePasswordVisibility(clientId);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

PasswordDisplay.propTypes = {
    clientId: PropTypes.string,
    password: PropTypes.string,
    isSelected: PropTypes.bool,
    togglePasswordVisibility: PropTypes.func,
};

export default PasswordDisplay;
